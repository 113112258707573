import { useState, useEffect, useContext, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft, faCircle } from "@fortawesome/free-solid-svg-icons";
import { format, differenceInHours, isEqual } from "date-fns";

import { ServicesContext } from "../../../../contexts/services";

import { useParams } from "react-router-dom";

import NotFound from "../../../../assets/imgs/house.svg";

import "./style.css";

function DetailedIncident() {
  const [incident, setIncident] = useState(null);
  const [details, setDetails] = useState([]);

  const { incidents } = useContext(ServicesContext);
  const params = useParams();

  const getIncidentData = useCallback(
    (id) => {
      const incidentInfo = incidents.filter((incident) => incident._id === id);
      if (incidentInfo.length > 0) setIncident(incidentInfo[0]);
    },
    [incidents]
  );

  useEffect(() => {
    if (!!params) {
      getIncidentData(params.id);
    }
  }, [params, getIncidentData]);

  useEffect(() => {
    if (incident) {
      const { details } = incident;
      reorderDetails(details);
    }
  }, [incident]);

  const reorderDetails = (details) => {
    let detailsArray = details
      .filter((detail) => !detail.hiddenDetail)
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    setDetails(detailsArray);
  };

  const calculateIncidentInterval = (init, end) => {
    const initialDate = new Date(init);
    const finalDate = end ? new Date(end) : new Date(init);
    const today = new Date();
    let difference = 0;

    if (isEqual(initialDate, finalDate)) {
      difference = differenceInHours(today, initialDate);
    } else {
      difference = differenceInHours(finalDate, initialDate);
    }

    if (difference > 24) {
      return `${Math.round(difference / 24)} dias e ${difference % 24} horas`;
    } else if (difference <= 0) {
      return `Menos de uma hora`;
    } else {
      return `${difference}h`;
    }
  };

  return (
    <div className="detailed-incident-container">
      {!!incident ? (
        <div className="detailed-content">
          <div className="incident-information">
            <h3 className="incident-title">{incident.name}</h3>
            <div className="incident-details">
              <div className="incident-details-left">
                {details.length > 0 ? (
                  <>
                    {details.map((detail, index) => {
                      return (
                        <div
                          className="note"
                          key={`incident-detail-${index + 1}`}
                        >
                          {index === 0 ? (
                            <div className="incident-last-update">
                              <FontAwesomeIcon
                                icon={faCircle}
                                alt="incident status"
                                className="last-update-icon"
                              />
                            </div>
                          ) : (
                            <div className="circle"></div>
                          )}
                          <p>{detail.detail}</p>
                          <span className="incident-update-time">
                            {`Postado em ${format(
                              new Date(detail.created_at),
                              "dd/MM/yyyy"
                            )} às ${format(
                              new Date(detail.created_at),
                              "HH:mm"
                            )}`}
                          </span>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <div className="zero-details">
                      <img src={NotFound} alt="Uma casa" />
                      <p>
                        Ops, parece que ainda não foram adicionados detalhes a
                        este incidente.
                      </p>
                    </div>
                  </>
                )}
              </div>
              <div className="incident-details-right">
                <div className="incident-details-status">
                  <p className="bold-paragraph">Status do Incidente</p>
                  <p>{incident.statusIncident.name}</p>
                </div>
                {incident.statusIncident.name !== "Resolvido" ? (
                  <div className="incident-details-status">
                    <p className="bold-paragraph">Previsão de Retorno</p>
                    <p>{`${incident.forecast} horas`}</p>
                  </div>
                ) : null}
                <div className="incident-details-status">
                  <p className="bold-paragraph">Duração do Incidente</p>
                  <p>
                    {calculateIncidentInterval(
                      incident.eventStarted,
                      incident.eventEnded
                    )}
                  </p>
                </div>
                <div className="incident-affected-products">
                  <p className="bold-paragraph">Produtos Afetados</p>
                  {incident.affectedProducts.map((product, index) => (
                    <p key={`affected-product-${index + 1}`}>{product.name}</p>
                  )) ?? []}
                </div>
                <div className="incident-affected-services">
                  <p className="bold-paragraph">Serviços Afetados</p>
                  {incident.affectedServices.services.map((service, index) => (
                    <p key={`affected-product-${index + 1}`}>{service.name}</p>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <hr style={{ marginBottom: "15px" }} />
          <a href="/client/incidents" className="back-link">
            <FontAwesomeIcon
              icon={faAngleDoubleLeft}
              alt="double left angle"
              className="anchor-icon"
            />
            Histórico de Incidentes
          </a>
        </div>
      ) : (
        <p className="incident-details-error">
          Ocorreu um problema ao carregar o incidente. Por favor, tente
          novamente.
        </p>
      )}
    </div>
  );
}

export default DetailedIncident;
