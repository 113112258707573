import { useState, useEffect, useCallback } from "react";
import { format, addHours, differenceInHours } from "date-fns";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";

import BreadCrumb from "../../components/BreadCrumb";
import BlockDivider from "../../components/BlockDivider";

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./style.css";

import {
  getById,
  create,
  update,
  getIncidentsStatus,
} from "../../../../repositories/incidents";
import {
  getAllServices,
  getServicesStatus,
} from "../../../../repositories/services";
import { getAllProducts } from "../../../../repositories/products";

function Incident({ match, history }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [incidentId, setIncidentId] = useState("");
  const [values, setValues] = useState({
    name: "",
    statusIncident: "",
    forecast: null,
    eventStarted: null,
    eventEnded: null,
    details: [],
  });
  const [detail, setDetail] = useState("");
  const [hiddenDetail, setHiddenDetail] = useState(false);
  const [services, setServices] = useState([]);
  const [products, setProducts] = useState([]);
  const [incidentStatus, setIncidentStatus] = useState([]);
  const [ServiceStatus, setServiceStatus] = useState([]);
  const [newProductArray, setNewProductArray] = useState([]);
  const [newServiceArray, setNewServiceArray] = useState([]);
  const [listedServicesStatus, setListedServicesStatus] = useState("");

  const settingInfoToForm = useCallback((values) => {
    const {
      name,
      statusIncident,
      forecast,
      eventStarted,
      eventEnded,
      details,
      affectedServices,
      affectedProducts,
    } = values;

    setValues({
      name: name ? name : null,
      statusIncident: statusIncident ? statusIncident._id : null,
      forecast: forecast
        ? format(
            addHours(new Date(eventStarted), new Date(forecast)),
            "yyyy-MM-dd'T'HH:mm"
          )
        : null,
      eventStarted: eventStarted
        ? format(new Date(eventStarted), "yyyy-MM-dd'T'HH:mm")
        : null,
      eventEnded: eventEnded
        ? format(new Date(eventEnded), "yyyy-MM-dd'T'HH:mm")
        : null,
      details: !!details ? details : [],
    });

    setListedServicesStatus(affectedServices.statusServices);

    setNewServiceArray(affectedServices.services.map((service) => service._id));

    setNewProductArray(affectedProducts.map((product) => product._id));
  }, []);

  const loadIncidentInfo = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getById(incidentId);
      settingInfoToForm(response);
    } catch (err) {
      toast.error("Ocorreu um problema ao carregar os dados do incidente.");
    } finally {
      setIsLoading(false);
    }
  }, [incidentId, settingInfoToForm]);

  const loadServices = async () => {
    try {
      const response = await getAllServices();
      setServices(response);
    } catch (err) {
      toast.error("Ocorreu um problema ao carregar os serviços.");
    }
  };

  const loadProducts = async () => {
    try {
      const response = await getAllProducts();
      setProducts(response);
    } catch (err) {
      toast.error("Ocorreu um problema ao carregar os produtos.");
    }
  };

  const loadIncidentStatus = async () => {
    try {
      const response = await getIncidentsStatus();
      setIncidentStatus(response);
    } catch (err) {
      toast.error("Ocorreu um problema ao carregar os status de incidentes.");
    }
  };

  const loadServiceStatus = async () => {
    try {
      const response = await getServicesStatus();
      setServiceStatus(response);
    } catch (err) {
      toast.error("Ocorreu um problema ao carregar os status de serviços.");
    }
  };

  useEffect(() => {
    loadServices();
    loadProducts();
    loadIncidentStatus();
    loadServiceStatus();
  }, []);

  useEffect(() => {
    if (!!match.params.id) {
      setIncidentId(match.params.id);
    }
  }, [match.params.id]);

  useEffect(() => {
    if (!incidentId) {
      document.title = "Novo Incidente";
    } else {
      loadIncidentInfo();
    }
  }, [incidentId, loadIncidentInfo]);

  const handleChangeProductsCheckBox = (event) => {
    const {
      target: { value },
    } = event;

    setNewProductArray(value);
  };

  const handleChangeServicesCheckBox = (event) => {
    const {
      target: { value },
    } = event;
    setNewServiceArray(value);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const buildObject = () => {
    let object = {
      ...values,
      forecast: differenceInHours(
        new Date(values.forecast),
        new Date(values.eventStarted)
      ),
      eventEnded: values.eventEnded ? values.eventEnded : null,
      affectedProducts: newProductArray,
      affectedServices: {
        services: newServiceArray,
        statusServices: listedServicesStatus,
      },
    };

    return object;
  };

  const validateDateInputs = () => {
    if(!values.name) return (toast.warn("Preencha o campo 'Nome do Incidente'"), false);

    if (!values.statusIncident) return (toast.warn("Preencha o campo 'Status do Incidente'"), false);

    if (!values.forecast) return (toast.warn("Preencha o campo 'Previsão de Retorno'"), false);
    else if (values?.forecast?.length > 16)
      return (toast.warn("Preencha corretamente a data e horário de 'Previsão de Retorno'"), false);
    else if (new Date(values.forecast) < new Date(values.eventStarted))
      return (toast.warn(
        "A data e horário de 'Previsão de Retorno' deve ser maior que a data e horário de 'Início do Incidente'"
      ), false);

    if (!newProductArray || !newProductArray.length) return (toast.warn("Preencha o campo 'Produtos Afetados'"), false);

    if (!newServiceArray || !newServiceArray.length) return (toast.warn("Preencha o campo 'Serviços Afetados'"), false);

    if (!listedServicesStatus) return (toast.warn("Preencha o campo 'Status dos Serviços'"), false);
    
    if (!values.eventStarted) return (toast.warn("Preencha o campo 'Início do Incidente'"), false);
    else if (values?.eventStarted?.length > 16)
      return (toast.warn("Preencha corretamente a data e horário de 'Início do Incidente'"), false);
    else if (new Date(values.eventStarted) > new Date())
      return (toast.warn(
        "A data e horário de 'Início do Incidente' deve ser menor ou igual a data e horário atual"
      ), false);

    if (values.statusIncident === "61cc77eb4a067f2b9f9676ab" && !values.eventEnded)
      return (toast.warn("Preencha o campo 'Final de Incidente'"), false);
    else if (values?.eventEnded?.length > 16)
      return (toast.warn("Preencha corretamente a data e horário de 'Final de Incidente'"), false);
    else if (values.eventEnded && new Date(values.eventEnded) < new Date(values.eventStarted))
      return (toast.warn(
        "A data e horário do 'Final de Incidente' deve ser maior que a data e horário de 'Início de Incidente'"
      ), false);

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validData = validateDateInputs();

    if (validData) {
      const incidentObject = buildObject();

      if (!!incidentId) {
        updateIncident(incidentObject);
      } else {
        createIncident(incidentObject);
      }
    }
  };

  const addDetail = () => {
    if (detail.length > 0 && values.statusIncident) {
      let newDetail = {
        detail: detail,
        statusIncident: values.statusIncident,
        hiddenDetail: hiddenDetail,
        created_at: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
      };
      let newDetailsArray = values.details
        ? [...values.details, newDetail]
        : [newDetail];

      setValues({ ...values, details: newDetailsArray });
      setDetail("");
      setHiddenDetail(false);
    } else if (!values.statusIncident) {
      toast.warning(
        "Para adicionar um novo detalhe, selecione o status do incidente"
      );
    }
  };

  const removeDetail = (positionDetail) => {
    values.details.splice(positionDetail, 1);
    setValues({ ...values, details: values.details });
  };

  const createIncident = async (incident) => {
    try {
      setIsSaving(true);
      await create(incident);
      toast.success("O incidente foi cadastrado com sucesso!");
      history.goBack();
    } catch (err) {
      toast.error(`Ocorreu um problema ao salvar o incidente. ${err}`);
    } finally {
      setIsSaving(false);
    }
  };

  const updateIncident = async (incident) => {
    try {
      setIsSaving(true);
      await update({
        _id: incidentId,
        ...incident,
      });
      toast.success("O incidente foi atualizado com sucesso!");
      history.goBack();
    } catch (err) {
      toast.error(`Ocorreu um problema ao atualizar o incidente. ${err}`);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      <BreadCrumb
        path={["", "", null]}
        data={[
          "Início",
          "Incidentes",
          match.params.id ? "Editar Incidente" : "Novo Incidente",
        ]}
      />
      <form onSubmit={handleSubmit} noValidate>
        <div>
          <BlockDivider label="Dados Gerais" />
          <TextField
            id="incident-name-field"
            label="Nome do Incidente"
            variant="standard"
            sx={[{ m: 1, width: "50%" }]}
            value={values.name}
            onChange={handleChange("name")}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 100,
            }}
          />

          <FormControl variant="standard" sx={{ m: 1, width: 200 }}>
            <InputLabel id="incident-status-select-label" shrink>
              Status do Incidente
            </InputLabel>
            <Select
              labelId="incident-status-select-label"
              id="incident-status-select"
              label="Status do Incidente"
              displayEmpty
              value={values.statusIncident}
              onChange={handleChange("statusIncident")}
            >
              <MenuItem value="">
                <em>Selecione...</em>
              </MenuItem>
              {incidentStatus.map((status) => (
                <MenuItem
                  key={`incident-status-${status._id}`}
                  value={status._id}
                >
                  {status.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            id="forecast-datetime-picker"
            label="Previsão de Retorno"
            type="datetime-local"
            value={values.forecast}
            onChange={handleChange("forecast")}
            sx={{ width: 250, margin: "0.5rem" }}
            variant="standard"
            inputProps={{
              pattern: "[0-9]",
            }}
          />
        </div>

        <div className="data-block">
          <BlockDivider label="Afetados" />
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="affected-products-multiple-checkbox-label">
              Produtos Afetados
            </InputLabel>
            <Select
              defaultValue=""
              labelId="affected-products-multiple-checkbox-label"
              id="affected-products-multiple-checkbox"
              multiple
              value={newProductArray.map((product) => product)}
              onChange={handleChangeProductsCheckBox}
              input={<OutlinedInput label="Produtos Afetados" />}
              renderValue={(selected) =>
                selected
                  .map((option) => {
                    const [name] = products
                      .filter((product) => product._id === option)
                      .map((o) => o.name);
                    return name ?? "";
                  })
                  .join(", ")
              }
            >
              {products.map((product, index) => (
                <MenuItem
                  key={`product-${product._id}-${index}`}
                  value={product._id}
                >
                  <Checkbox
                    checked={
                      newProductArray.findIndex((x) => x === product._id) > -1
                    }
                  />
                  {product.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ m: 1, width: 400 }}>
            <InputLabel id="affected=services-multiple-checkbox-label">
              Serviços Afetados
            </InputLabel>
            <Select
              labelId="affected=services-multiple-checkbox-label"
              id="affected=services-multiple-checkbox"
              multiple
              value={newServiceArray.map((service) => service)}
              onChange={handleChangeServicesCheckBox}
              input={<OutlinedInput label="Serviços Afetados" />}
              renderValue={(selected) =>
                selected
                  .map((option) => {
                    const [name] = services
                      .filter((service) => service._id === option)
                      .map((o) => o.name);
                    return name ?? "";
                  })
                  .join(", ")
              }
            >
              {services.map((service, index) => (
                <MenuItem
                  key={`service-${service._id}-${index}`}
                  value={service._id}
                >
                  <Checkbox
                    checked={
                      newServiceArray.findIndex((x) => x === service._id) > -1
                    }
                  />
                  {`${service?.productId ? service?.productId?.name : null} - ${
                    service.name
                  }`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="standard" sx={{ m: 1, width: 200 }}>
            <InputLabel id="incident-status-select-label" shrink>
              Status dos Serviços
            </InputLabel>
            <Select
              labelId="incident-status-select-label"
              id="incident-status-select"
              label="Status dos Serviços"
              value={listedServicesStatus}
              onChange={(e) => setListedServicesStatus(e.target.value)}
              sx={{ paddingTop: "0.3125rem" }}
              displayEmpty
            >
              <MenuItem value="">
                <em>Selecione...</em>
              </MenuItem>
              {ServiceStatus.map((status) => (
                <MenuItem key={`status-${status._id}`} value={status._id}>
                  {status.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="data-block">
          <BlockDivider label="Horários do Incidente" />
          <TextField
            id="datetime-local"
            label="Início do Incidente"
            type="datetime-local"
            value={values.eventStarted}
            onChange={handleChange("eventStarted")}
            sx={{ width: 250, margin: "0.5rem 0.625rem 0 0.5rem" }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="standard"
            inputProps={{
              pattern: "[0-9]",
            }}
          />

          <TextField
            id="datetime-local"
            label="Final do Incidente"
            type="datetime-local"
            value={values.eventEnded}
            onChange={handleChange("eventEnded")}
            sx={{ width: 250, margin: "0.5rem 0 0 0.9375rem" }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="standard"
            inputProps={{
              pattern: "[0-9]",
            }}
          />
        </div>

        <div className="data-block">
          <BlockDivider label="Detalhes do Incidente" />

          <div>
            <div className="details-block">
              <TextField
                id="detail-input"
                label="Novo Detalhe"
                multiline
                maxRows={4}
                onChange={(e) => setDetail(e.target.value)}
                value={detail}
                sx={{ width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              className="details-block"
            >
              <FormControlLabel
                label="Privado"
                control={
                  <Checkbox
                    size="small"
                    checked={hiddenDetail}
                    onChange={(e) => setHiddenDetail(e.target.checked)}
                  />
                }
              />
              <Button
                variant="outlined"
                disabled={!detail || !detail.trim()}
                onClick={addDetail}
                size="small"
              >
                Adicionar Detalhe
              </Button>
            </div>
          </div>

          <div className="incident-details-left">
            {values.details?.map((detail, index) => {
              return (
                <div className="note" key={`incident-detail-${index + 1}`}>
                  {detail.hiddenDetail ? (
                    <>
                      <p className="incident-details-paragraph private">
                        {detail.detail}
                      </p>
                      <span className="incident-update-time private">
                        {`Postado em ${format(
                          new Date(detail.created_at),
                          "dd/MM/yyyy"
                        )} às ${format(new Date(detail.created_at), "HH:mm")}`}
                      </span>
                    </>
                  ) : (
                    <>
                      <p className="incident-details-paragraph">
                        {detail.detail}
                      </p>
                      <span className="incident-update-time">
                        {`Postado em ${format(
                          new Date(detail.created_at),
                          "dd/MM/yyyy"
                        )} às ${format(new Date(detail.created_at), "HH:mm")}`}
                      </span>
                    </>
                  )}
                  <FontAwesomeIcon
                    icon={faTrash}
                    color="red"
                    onClick={() => {
                      removeDetail(index);
                    }}
                    className="icon-delete-detail"
                  />
                </div>
              );
            })}
          </div>
        </div>

        <Box sx={{ marginTop: "1.875rem" }}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <LoadingButton
              loading={isSaving}
              variant="contained"
              color="success"
              loadingIndicator="SALVANDO..."
              sx={{
                marginRight: "0.625rem",
                width: "6.875rem",
              }}
              type="submit"
            >
              Salvar
            </LoadingButton>
            <Button
              variant="contained"
              color="error"
              onClick={() => history.goBack()}
            >
              Cancelar
            </Button>
          </div>
        </Box>
      </form>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default Incident;
