import { getCookie } from "./cookies";

export function isAdminLogged() {
  const id = getCookie("ADMIN_ID");
  const name = getCookie("ADMIN_NAME");

  if (!id || !name) return false;

  return true;
}

export function onlyNumbers(value) {
  return value.replace(/\D/g, "");
}

export function description(value) {
  if (!value.match(/^[a-zA-Z-çÇ-ãÃ-õÕ-áÁ-úÚ0-9/\-/-/./ ()+: s]+$/g)) {
    value = value.replace(value.charAt([value.length - 1]), "");
    return value;
  }
  return value;
}

export function forName(value) {
  if (!value.match(/^[a-zA-Z-çÇ-ãÃ-õÕ0-9\s]+$/)) {
    value = value.replace(value.charAt([value.length - 1]), "");
    return value;
  }
  return value;
}

export function maxLength(value, length) {
  return value.substring(0, length);
}
