import { useState } from "react";

import Card from "../Card";
import GridHeader from "../GridHeader";

import Engines from "../../../../assets/imgs/kenlo-house.svg";

import "./style.css";

const Collapse = ({ product }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const activeServices = product.productServices.filter(
    (service) => service.status
  );

  return (
    <>
      <button
        type="button"
        className={`collapse-button ${
          product?.affectedServices.length > 0
            ? "border-failure"
            : "border-operational"
        }`}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <div className="button-label">
          <p className="product-label">{product.product}</p>
        </div>
        <div className="status-label">
          <p
            className={`${
              product?.affectedServices.length > 0 ? "incident" : "operational"
            }`}
          >
            {product?.affectedServices.length > 0
              ? "Ocorrência em andamento"
              : "Operacional"}
          </p>
        </div>
      </button>
      <div className={`content ${isCollapsed ? "collapsed" : "expanded"}`}>
        <div className="content-wrapper">
          {activeServices.length > 0 ? (
            <>
              <GridHeader />
              <div className="cards-wrapper">
                <div className="status-cards">
                  {activeServices.map((service, index) => {
                    return <Card key={`card-${index + 1}`} service={service} />;
                  })}
                </div>
              </div>
            </>
          ) : (
            <div className="no-services-output">
              <img
                src={Engines}
                className="no-services-engines"
                alt="Engrenagens"
              />
              <p>
                Ops, parece que ainda não há serviços listados para este
                produto.
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Collapse;
