import Main from "./pages/main";
import Products from "./pages/products";
import Product from "./pages/product";
import Services from "./pages/services";
import Service from "./pages/service";
import Incidents from "./pages/incidents";
import Incident from "./pages/incident";

const AdminRoutes = [
  {
    path: "/",
    name: "Página Inicial",
    component: Main,
    layout: "/admin",
  },
  {
    path: "/products",
    name: "Produtos",
    component: Products,
    layout: "/admin",
  },
  {
    path: "/product",
    name: "Novo Produto",
    component: Product,
    layout: "/admin",
  },
  {
    path: "/product/:id",
    name: "Editar Produto",
    component: Product,
    layout: "/admin",
  },
  {
    path: "/incidents",
    name: "Incidentes",
    component: Incidents,
    layout: "/admin",
  },
  {
    path: "/incident",
    name: "Novo Incidente",
    component: Incident,
    layout: "/admin",
  },
  {
    path: "/incident/:id",
    name: "Editar Incidente",
    component: Incident,
    layout: "/admin",
  },
  {
    path: "/services",
    name: "Serviços",
    component: Services,
    layout: "/admin",
  },
  {
    path: "/service",
    name: "Novo Serviço",
    component: Service,
    layout: "/admin",
  },
  {
    path: "/service/:id",
    name: "Editar Serviço",
    component: Service,
    layout: "/admin",
  },
];

export default AdminRoutes;
