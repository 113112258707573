import Main from "./pages/main";
import Incidents from "./pages/incidents";
import Incident from "./pages/detailedIncident";

const ClientRoutes = [
    {
      path: "/",
      name: "Página Inicial",
      component: Main,
      layout: "/client",
    },
    {
      path: "/incidents",
      name: "Incidentes",
      component: Incidents,
      layout: "/client",
    },
    {
      path: "/incidents/:id",
      name: "Detalhe do Incidente",
      component: Incident,
      layout: "/client",
    },
  ];
  
  export default ClientRoutes;