import { useEffect, useState, useCallback, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";

import { ServicesContext } from "../../../../contexts/services";

import CurrentStatus from "../../components/CurrentStatus";
import Collapse from "../../components/Collapsible";

import "./style.css";

function Main() {
  const [products, setProducts] = useState([]);

  const { formatedInfo } = useContext(ServicesContext);

  const getData = useCallback(async () => {
    const { products } = formatedInfo;
    setProducts(products.filter((product) => product.status));
  }, [formatedInfo]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <div className="main-principal">
        <main className="main-content">
          <CurrentStatus />
          {products.length > 0 &&
            products.map((item, index) => {
              return (
                <Collapse
                  key={`collapsible-card-${index + 1}`}
                  product={item}
                />
              );
            })}
          <hr />
          <div className="dashboard-footer">
            <a href="/client/incidents">
              <FontAwesomeIcon
                icon={faAngleDoubleLeft}
                alt="double left angle"
                className="anchor-icon"
              />
              Histórico de Incidentes
            </a>
            <p>¹ O SLA é calculado com base no Uptime dos últimos 30 dias</p>
          </div>
        </main>
      </div>
    </>
  );
}

export default Main;
