import { useState } from "react";

const useFilters = (filters) => {
  const [status, setStatus] = useState(filters?.status || "");
  const [query, setQuery] = useState(filters?.query || "");
  const [type, setType] = useState(filters?.type || "");

  const filterByQuery = (attributesToSearch) => {
    if (!query) {
      return true;
    }
    const result = attributesToSearch.map((attribute) =>
      attribute?.toString().toLowerCase().includes(query.toLowerCase())
    );
    return result.some((res) => res);
  };

  const filterByType = (attribute, object) => {
    if (!type) return true;

    return String(object[attribute]) === String(type);
  };

  const filterByStatus = (attribute, object) => {
    if (!status) return true;

    return String(object[attribute]) === String(status);
  };

  return {
    queryFilter: {
      query,
      setQuery,
      filterByQuery,
    },

    typeFilter: {
      type,
      setType,
      filterByType,
    },

    statusFilter: {
      status,
      setStatus,
      filterByStatus,
    },
  };
};

export default useFilters;
