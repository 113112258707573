import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
  faFlag,
  faTools,
  faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";

import "./style.css";

const IconsSubtitle = () => {
  return (
    <>
      <div className="icons-subtitle-content">
        <div className="icons-subtitles">
          <FontAwesomeIcon
            icon={faCheckCircle}
            alt="status icon"
            className="subtitle-icon operational"
          />
          <p>Operacional</p>
        </div>
        <div className="icons-subtitles">
          <FontAwesomeIcon
            icon={faExclamationCircle}
            alt="status icon"
            className="subtitle-icon incident"
          />
          <p>Incidente</p>
        </div>
        <div className="icons-subtitles">
          <FontAwesomeIcon
            icon={faFlag}
            alt="status icon"
            className="subtitle-icon alert"
          />
          <p>Aviso</p>
        </div>
        <div className="icons-subtitles">
          <FontAwesomeIcon
            icon={faTools}
            alt="status icon"
            className="subtitle-icon maintenance"
          />
          <p>Manutenção</p>
        </div>
        <div className="icons-subtitles">
          <FontAwesomeIcon
            icon={faMinusCircle}
            alt="status icon"
            className="subtitle-icon failure"
          />
          <p>Indisponível</p>
        </div>
      </div>
    </>
  );
};

export default IconsSubtitle;
