import { useState, useCallback, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import BreadCrumb from "../../components/BreadCrumb";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import AlertDialog from "../../../../components/AlertDialog";
import useFilters from "../../../../hooks/useFilters";

import {
  getAllProducts,
  deleteProduct,
} from "../../../../repositories/products";

import "./style.css";

function Products({ history }) {
  const [listOfProducts, setListOfProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [id, setId] = useState("");

  const { queryFilter, statusFilter } = useFilters();
  const { query, setQuery, filterByQuery } = queryFilter;
  const { status, setStatus, filterByStatus } = statusFilter;

  const loadProducts = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getAllProducts();

      setListOfProducts(response);
    } catch (err) {
      toast.error(
        "Ocorreu um problema ao carregar os produtos. Por favor, tente novamente."
      );
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  const handleFilters = (product) => {
    const querySearch = [product["name"]];
    return filterByQuery(querySearch) && filterByStatus("status", product);
  };

  const handleOpenModal = (id) => {
    setIsAlertModalOpen(true);
    setId(id);
  };

  const handleProductDelete = async () => {
    try {
      setIsAlertModalOpen(false);
      setLoading(true);
      await deleteProduct(id);
      loadProducts();
      toast.success(`O incidente foi excluído com sucesso.`);
    } catch (err) {
      toast.error(`Ocorreu um problema ao excluir o incidente. ${err}`);
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "name",
      },
      {
        Header: "Status",
        accessor: "status",
        width: 200,
        Cell: (props) => (props.value ? "Ativo" : "Inativo"),
      },
      {
        Header: "Ações",
        acessor: "id",
        width: 100,
        Cell: (props) => (
          <>
            <Link to={`product/${props.original._id}`}>
              <FontAwesomeIcon icon={faEdit} color="#f82e52" />
            </Link>
            <FontAwesomeIcon
              icon={faTrashAlt}
              cursor="pointer"
              color="#f82e52"
              style={{ marginLeft: 5 }}
              onClick={() => handleOpenModal(props.original._id)}
            />
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <BreadCrumb path={["", null]} data={["Início", "Produtos"]} />
      <div style={{ display: "flex", marginBottom: "10px" }}>
        <Button
          variant="contained"
          size="large"
          onClick={() => history.push("product")}
          sx={{ width: "200px" }}
        >
          + Novo Produto
        </Button>
        <TextField
          label="Pesquise por Nome"
          id="outlined-size-small"
          size="small"
          sx={{ margin: "2px 0 0 10px", width: "60%" }}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <div
          style={{ display: "flex", flexDirection: "row", paddingLeft: "10px" }}
        >
          <span style={{ display: "flex", alignItems: "center" }}>
            <strong>Status: </strong>
          </span>
          <Select
            id="status-select"
            value={status}
            displayEmpty
            size="small"
            onChange={(e) => setStatus(e.target.value)}
            sx={{ marginLeft: "10px", width: "150px" }}
          >
            <MenuItem value="">Ambos</MenuItem>
            <MenuItem value="true">Ativo</MenuItem>
            <MenuItem value="false">Inativo</MenuItem>
          </Select>
        </div>
      </div>
      <ReactTable
        style={{ textAlign: "center", fontWeight: "600" }}
        data={listOfProducts.filter(handleFilters)}
        columns={columns}
        defaultPageSize={10}
        showPagination
        sortable
        showPaginationTop={false}
        showPaginationBottom
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        loading={loading}
        noDataText="Nenhum produto encontrado"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
        defaultSorted={[
          {
            id: "name",
            desc: false,
          },
        ]}
      />

      {isAlertModalOpen && (
        <AlertDialog
          show={isAlertModalOpen}
          onHide={() => setIsAlertModalOpen(false)}
          title="Deseja realmente excluir o produto?"
          message=""
          onCancel={() => setIsAlertModalOpen(false)}
          onSubmit={() => handleProductDelete()}
        />
      )}
    </>
  );
}

export default Products;
