import ErrorEngines from "../../assets/imgs/engines_background.svg"

import "./style.css";

const ErrorBoundary = () => {
  return (
    <div className="error-boundary">
      <img src={ErrorEngines} alt="Engrenagens"/>
      <p>Ops, parece que houve um problema!</p>
      <p>Por favor, recarregue a página e tente novamente.</p>
    </div>
  );
};

export default ErrorBoundary;
