import { useEffect, useState, useCallback, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";

import { ServicesContext } from "../../../../contexts/services";

import "./style.css";

function Incidents() {
  const [pastIncidents, setPastIncidents] = useState([]);
  const [currentIncidents, setCurrentIncidents] = useState([]);

  const { incidents } = useContext(ServicesContext);

  const convertDate = (date) => {
    return format(new Date(date), "dd/MM/yyyy");
  };

  const filterIncidents = useCallback(() => {
    const pastIncidents = incidents.filter(
      (incident) => incident.statusIncident.name === "Resolvido"
    );
    const currentIncidents = incidents.filter(
      (incident) => incident.statusIncident.name !== "Resolvido"
    );

    setPastIncidents(pastIncidents);
    setCurrentIncidents(currentIncidents);
  }, [incidents]);

  useEffect(() => {
    filterIncidents();
  }, [filterIncidents]);

  return (
    <div className="incidents-container">
      <h2>Incidentes Ativos</h2>
      {currentIncidents.length > 0 ? (
        <>
          <table className="incidents-table">
            <tbody>
              {currentIncidents.map((incident) => {
                return (
                  <tr key={`incident-${incident._id}`}>
                    <td className="table-card">
                      <a href={`incidents/${incident._id}`}>{incident.name}</a>
                      <p className="incident-date">
                        {incident.eventStarted
                          ? convertDate(incident.eventStarted)
                          : null}
                      </p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      ) : (
        <p>Oba, não há incidentes ativos!</p>
      )}
      <h2>Histórico de Incidentes</h2>
      {pastIncidents.length > 0 ? (
        <>
          <table className="incidents-table">
            <tbody>
              {pastIncidents.map((incident) => {
                return (
                  <tr key={`incident-${incident._id}`}>
                    <td className="table-card">
                      <a href={`/client/incidents/${incident._id}`}>
                        {incident.name}
                      </a>
                      <p className="incident-date">
                        {incident.eventStarted
                          ? convertDate(incident.eventStarted)
                          : "--"}
                      </p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      ) : (
        <p>Não há incidentes listados</p>
      )}

      <hr style={{ marginBottom: "15px" }} />
      <a href="/client/" className="incidents-back-link">
        <FontAwesomeIcon
          icon={faAngleDoubleLeft}
          alt="double left angle"
          className="incidents-anchor-icon"
        />
        Status Atual
      </a>
    </div>
  );
}

export default Incidents;
