import { Route, Switch } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";

import routes from "./routes";

function ClientLayout() {
  function getRoutes(routes) {
    return routes.map((prop, key) => (
      <Route
        exact
        path={prop.layout + prop.path}
        render={props => <prop.component {...props} />}
        key={key}
      />
    ));
  }

  return (
    <>
      <Header />
      <div className="navfiller" />
      <Switch>{getRoutes(routes)}</Switch>
      <Footer />
    </>
  );
}

export default ClientLayout;
