import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
  faFlag,
  faTools,
  faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";

import "./style.css";

const Card = ({ service }) => {
  const { name, description, statusServiceId } = service;

  const statusIcons = {
    Operacional: faCheckCircle,
    Manutenção: faTools,
    Aviso: faFlag,
    Incidente: faExclamationCircle,
    Indisponível: faMinusCircle,
  };

  const iconClasses = {
    Operacional: "operational",
    Manutenção: "maintenance",
    Aviso: "alert",
    Incidente: "incident",
    Indisponível: "failure",
  };

  return (
    <div className="service-card">
      <div className="card">
        <div className="card-top-label">
          {statusServiceId.name !== 'Operacional'?
            <a 
            className="service-name has-occurrence" data-tip={`${description}`}
            href="/client/incidents" 
            target="_blank"
            >
             {name}
             <FontAwesomeIcon
                icon={statusIcons[statusServiceId?.name] || faCheckCircle}
                alt="status check"
                className={`icon ${
                  iconClasses[statusServiceId.name] || "operational"
                }`}
              />
            </a>
            :
            <span className="service-name" data-tip={`${description}`}>
              {name}
              <FontAwesomeIcon
                icon={statusIcons[statusServiceId?.name] || faCheckCircle}
                alt="status check"
                className={`icon ${
                  iconClasses[statusServiceId.name] || "operational"
                }`}
              />
            </span>
          }
          <p data-tip="Uptime para os últimos 30 dias">{`¹SLA: ${service.sla}%`}</p>
          <span className="service-status-msg">{statusServiceId.name}</span>
        </div>
      </div>
      <ReactTooltip />
    </div>
  );
};

export default Card;
