import { useMemo, useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import BreadCrumb from "../../components/BreadCrumb";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import AlertDialog from "../../../../components/AlertDialog";
import useFilters from "../../../../hooks/useFilters";

import "./style.css";
import {
  getAllServices,
  getServicesStatus,
  deleteService,
} from "../../../../repositories/services";
import { getAllProducts } from "../../../../repositories/products";

function Services({ history }) {
  const [listOfServices, setListOfServices] = useState([]);
  const [products, setProducts] = useState([]);
  const [servicesStatus, setServicesStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [id, setId] = useState("");

  const { queryFilter, statusFilter, typeFilter } = useFilters();
  const { query, setQuery, filterByQuery } = queryFilter;
  const { status, setStatus, filterByStatus } = statusFilter;
  const { type, setType, filterByType } = typeFilter;

  const loadAllServices = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getAllServices();
      const newArray = response.map((service) => {
        return {
          ...service,
          serviceProductId: service.productId._id,
          serviceProductName: service.productId.name,
          serviceStatusId: service.statusServiceId._id,
          serviceStatusName: service.statusServiceId.name,
        };
      });

      setListOfServices(newArray);
    } catch (err) {
      toast.error(
        "Ocorreu um problema ao carregar os serviços. Por favor, tente novamente."
      );
    } finally {
      setLoading(false);
    }
  }, []);

  const loadProducts = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getAllProducts();

      setProducts(response);
    } catch (err) {
      toast.error(
        "Ocorreu um problema ao carregar os produtos. Por favor, tente novamente."
      );
    } finally {
      setLoading(false);
    }
  }, []);

  const loadServicesStatus = useCallback(async () => {
    try {
      const response = await getServicesStatus();
      setServicesStatus(response);
    } catch (err) {
      toast.error("Ocorreu um problema ao carregar os status.");
    }
  }, []);

  useEffect(() => {
    loadAllServices();
    loadProducts();
    loadServicesStatus();
  }, [loadAllServices, loadProducts, loadServicesStatus]);

  const handleFilters = (service) => {
    const querySearch = [service["name"]];
    return (
      filterByQuery(querySearch) &&
      filterByStatus("serviceStatusId", service) &&
      filterByType("serviceProductId", service)
    );
  };

  const handleOpenModal = (id) => {
    setIsAlertModalOpen(true);
    setId(id);
  };

  const handleServiceDelete = async () => {
    try {
      setIsAlertModalOpen(false);
      setLoading(true);
      await deleteService(id);
      loadAllServices();
      toast.success(`O incidente foi excluído com sucesso.`);
    } catch (err) {
      toast.error(`Ocorreu um problema ao excluir o incidente. ${err}`);
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "name",
        Cell: (props) => (props.value ? props.value : "--"),
      },
      {
        Header: "Produto",
        accessor: "productId.name",
        Cell: (props) => (props.value ? props.value : "--"),
      },
      {
        Header: "Status",
        accessor: "statusServiceId.name",
        width: 120,
        Cell: (props) => (props.value ? props.value : "--"),
      },
      {
        Header: "Ativo?",
        accessor: "status",
        width: 100,
        Cell: (props) => (props.value ? "Ativo" : "Inativo"),
      },
      {
        Header: "Ações",
        acessor: "id",
        width: 100,
        Cell: (props) => (
          <>
            <Link to={`service/${props.original._id}`}>
              <FontAwesomeIcon icon={faEdit} color="#f82e52" />
            </Link>
            <FontAwesomeIcon
              icon={faTrashAlt}
              cursor="pointer"
              color="#f82e52"
              style={{ marginLeft: 5 }}
              onClick={() => handleOpenModal(props.original._id)}
            />
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <BreadCrumb path={["", null]} data={["Início", "Serviços"]} />
      <div style={{ display: "flex", marginBottom: "10px" }}>
        <Button
          variant="contained"
          size="large"
          onClick={() => history.push("service")}
          sx={{ width: "200px" }}
        >
          + Novo Serviço
        </Button>
        <TextField
          label="Pesquise por Nome"
          id="outlined-size-small"
          size="small"
          sx={{ margin: "2px 0 0 10px", width: "40%" }}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <div
          style={{ display: "flex", flexDirection: "row", paddingLeft: "10px" }}
        >
          <span style={{ display: "flex", alignItems: "center" }}>
            <strong>Status: </strong>
          </span>
          <Select
            id="status-select"
            value={status}
            displayEmpty
            onChange={(e) => setStatus(e.target.value)}
            size="small"
            sx={{ marginLeft: "10px", width: "160px" }}
          >
            <MenuItem value="">Todos</MenuItem>
            {servicesStatus.map((status) => (
              <MenuItem
                key={`status-service-option-${status._id}`}
                value={status._id}
              >
                {status.name}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div
          style={{ display: "flex", flexDirection: "row", paddingLeft: "10px" }}
        >
          <span style={{ display: "flex", alignItems: "center" }}>
            <strong>Produto: </strong>
          </span>
          <Select
            id="status-select"
            value={type}
            displayEmpty
            onChange={(e) => setType(e.target.value)}
            size="small"
            sx={{ marginLeft: "10px", width: "160px" }}
          >
            <MenuItem value="">Todos</MenuItem>
            {products.map((product) => (
              <MenuItem
                key={`product-option-${product._id}`}
                value={product._id}
              >
                {product.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>
      <ReactTable
        style={{ textAlign: "center", fontWeight: "600" }}
        data={listOfServices.filter(handleFilters)}
        columns={columns}
        defaultPageSize={10}
        showPagination
        sortable
        showPaginationTop={false}
        showPaginationBottom
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        loading={loading}
        noDataText="Nenhum serviço encontrado"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
        defaultSorted={[
          {
            id: "name",
            desc: false,
          },
        ]}
      />

      {isAlertModalOpen && (
        <AlertDialog
          show={isAlertModalOpen}
          onHide={() => setIsAlertModalOpen(false)}
          title="Deseja realmente excluir o serviço?"
          message=""
          onCancel={() => setIsAlertModalOpen(false)}
          onSubmit={() => handleServiceDelete()}
        />
      )}
    </>
  );
}

export default Services;
