import kenloLogo from "../../../../assets/imgs/kenlo.svg";
import "./style.css";

const dotenv = require('dotenv');

dotenv.config();

const Header = () => {
  return (
    <div id="navbar-section" className="general-scope sticky-header">
      <div className="section navbar-filler">
        <div className="navbar">
          <div className="logo menu-left">
            <a href={process.env.REACT_APP_REDIRECT_URL}>
              <img
                src={kenloLogo}
                width="102px"
                height="30px"
                alt="Logotipo da Kenlo"
              />
            </a>
          </div>
          <div>
            <span className="header-span">Status</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
