import { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";

import BreadCrumb from "../../components/BreadCrumb";
import BlockDivider from "../../components/BlockDivider";

import { forName } from "../../../../utils/validations";

import {
  getById,
  getServicesStatus,
  create,
  update,
} from "../../../../repositories/services";
import { getAllProducts } from "../../../../repositories/products";

import "./style.css";

function Service({ match, history }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [serviceId, setServiceId] = useState("");
  const [values, setValues] = useState({
    name: "",
    statusServiceId: "",
    productId: "",
    description: "",
    sla: "",
    relevance: 1,
  });
  const [isActive, setIsActive] = useState(true);
  const [serviceLevelAgreement, setServiceLevelAgreement] = useState(1);
  const [products, setProducts] = useState([]);
  const [servicesStatus, setServicesStatus] = useState([]);

  const loadProducts = async () => {
    try {
      const response = await getAllProducts();
      setProducts(response);
    } catch (err) {
      toast.error("Ocorreu um problema ao carregar os produtos.");
    }
  };

  const loadServicesStatus = async () => {
    try {
      const response = await getServicesStatus();
      setServicesStatus(response);
    } catch (err) {
      toast.error("Ocorreu um problema ao carregar os status.");
    }
  };

  const settingInfoToForm = useCallback((values) => {
    const {
      name,
      statusServiceId,
      status,
      sla,
      productId,
      description,
      relevance,
    } = values;

    setValues({
      name: name ? name : null,
      statusServiceId: statusServiceId ? statusServiceId._id : null,
      productId: productId ? productId._id : null,
      description: description,
      relevance: relevance,
    });
    setIsActive(status);
    setServiceLevelAgreement(sla);
  }, []);

  const loadServiceInfo = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getById(serviceId);
      settingInfoToForm(response);
    } catch (err) {
      toast.error("Ocorreu um problema ao carregar os dados do serviço.");
    } finally {
      setIsLoading(false);
    }
  }, [serviceId, settingInfoToForm]);

  useEffect(() => {
    loadProducts();
    loadServicesStatus();
  }, []);

  useEffect(() => {
    if (!!match.params.id) {
      setServiceId(match.params.id);
    }
  }, [match.params.id]);

  useEffect(() => {
    if (!serviceId) {
      document.title = "Novo Serviço";
    } else {
      loadServiceInfo();
    }
  }, [serviceId, loadServiceInfo]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleIsActiveChange = (event) => {
    setIsActive(event.target.checked);
  };

  const handleServiceLevelAgreementChange = (value) => {
    if (value > 0 && value <= 100) {
      return setServiceLevelAgreement(value);
    } else {
      return toast.warn("O SLA deve ser maior que 0 e, menor ou igual a 100.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!!serviceId) {
      updateService(values);
    } else {
      createService(values);
    }
  };

  const createService = async (service) => {
    try {
      setIsSaving(true);
      await create({
        status: isActive,
        ...service,
        sla: serviceLevelAgreement,
      });
      toast.success("O serviço foi cadastrado com sucesso!");
      history.goBack();
    } catch (err) {
      toast.error(`Ocorreu um problema ao salvar o serviço. ${err}`);
    } finally {
      setIsSaving(false);
    }
  };

  const updateService = async (service) => {
    try {
      setIsSaving(true);
      await update({
        _id: serviceId,
        ...service,
        status: isActive,
        sla: serviceLevelAgreement,
      });
      toast.success("O serviço foi atualizado com sucesso!");
      history.goBack();
    } catch (err) {
      toast.error(`Ocorreu um problema ao atualizar o serviço. ${err}`);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      <BreadCrumb
        path={["", "", null]}
        data={[
          "Início",
          "Serviços",
          match.params.id ? "Editar Serviço" : "Novo Serviço",
        ]}
      />
      <form onSubmit={handleSubmit}>
        <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
          <div style={{ width: "100%" }}>
            <BlockDivider label="Dados Gerais" />
            <TextField
              label="Nome do Serviço"
              id="service-name-input"
              sx={[{ m: 1, width: "50%" }]}
              value={forName(values.name)}
              onChange={handleChange("name")}
              required
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 50,
              }}
            />

            <FormControl variant="standard" sx={{ m: 1, width: "15%" }}>
              <InputLabel id="status-select-input-label" shrink>
                Status
              </InputLabel>
              <Select
                labelId="status-select-label"
                id="status-select"
                displayEmpty
                value={values.statusServiceId}
                label="Status do Serviço"
                onChange={handleChange("statusServiceId")}
                required
              >
                <MenuItem value="">
                  <em>Selecione...</em>
                </MenuItem>
                {servicesStatus.map((status) => (
                  <MenuItem
                    key={`status-service-option-${status._id}`}
                    value={status._id}
                  >
                    {status.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="standard" sx={{ m: 1, width: 80 }}>
              <InputLabel id="service-relevance-select-label" shrink>
                Relevância
              </InputLabel>
              <Select
                labelId="service-relevance-select-label"
                id="service-relevance-select"
                value={values.relevance}
                label="Relevância"
                onChange={handleChange("relevance")}
                required
              >
                <MenuItem key="relevance-option-1" value={5}>
                  5
                </MenuItem>
                <MenuItem key="relevance-option-2" value={4}>
                  4
                </MenuItem>
                <MenuItem key="relevance-option-3" value={3}>
                  3
                </MenuItem>
                <MenuItem key="relevance-option-4" value={2}>
                  2
                </MenuItem>
                <MenuItem key="relevance-option-5" value={1}>
                  1
                </MenuItem>
              </Select>
            </FormControl>

            <TextField
              label="SLA"
              id="service-sla-input"
              sx={{ m: 1, width: "80px" }}
              variant="standard"
              value={serviceLevelAgreement}
              type="number"
              onChange={(e) =>
                handleServiceLevelAgreementChange(e.target.value)
              }
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                pattern: "[0-9]{1,3}",
              }}
            />

            <FormControlLabel
              control={
                <Switch checked={isActive} onChange={handleIsActiveChange} />
              }
              label="Ativo?"
              labelPlacement="top"
            />
          </div>
        </Box>

        <div>
          <BlockDivider label="Detalhes do Serviço" />
          <FormControl variant="standard" sx={{ m: 1, width: "30%" }}>
            <InputLabel id="product-select-input-label" shrink>
              Produto
            </InputLabel>
            <Select
              labelId="product-select-label"
              id="product-select"
              value={values.productId}
              displayEmpty
              label="Produto"
              onChange={handleChange("productId")}
              required
            >
              <MenuItem value="">
                <em>Selecione...</em>
              </MenuItem>
              {products.map((product) => (
                <MenuItem
                  key={`status-product-option-${product._id}`}
                  value={product._id}
                >
                  {product.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={[{ width: "60%", margin: "8px 5px 8px 8px" }]}>
            <TextField
              id="service-description-label"
              label="Descrição do Serviço"
              multiline
              maxRows={1}
              value={values.description}
              onChange={handleChange("description")}
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
            />
          </FormControl>
        </div>

        <Box sx={{ marginTop: "30px" }}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <LoadingButton
              loading={isSaving}
              variant="contained"
              color="success"
              loadingIndicator="SALVANDO..."
              sx={{
                marginRight: "10px",
                width: "110px",
              }}
              type="submit"
            >
              Salvar
            </LoadingButton>
            <Button
              variant="contained"
              color="error"
              onClick={() => history.goBack()}
            >
              Cancelar
            </Button>
          </div>
        </Box>
      </form>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default Service;
