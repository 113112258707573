import api from "../services/api";
import config from "../config";

import { getCookie } from "../utils/cookies";

export async function getTokenFromAPI(email) {
  try {
    const { data } = await api.post(config.endpoint + "status-page/users", {
      email: email,
    });
    return data;
  } catch (err) {
    throw new Error(
      `Ocorreu um erro ao obter o token. Por favor, tente novamente. Erro: ${err}`
    );
  }
}

export async function refreshToken() {
  try {
    const email = getCookie("ADMIN_EMAIL");
    const { token } = await getTokenFromAPI(email);

    localStorage.setItem("USER_TOKEN", token);
    localStorage.setItem("EXPIRES_IN", `${new Date() + 1}`);

    return token;
  } catch (err) {
    throw new Error(
      `Ocorreu um erro ao obter o token. Por favor, tente novamente. ${err}`
    );
  }
}
