import { firebaseConfig } from "./config";

import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";

initializeApp(firebaseConfig);
const auth = getAuth();

export async function firebaseAuthenticate(email, password) {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const { user } = userCredential;
    return user;
  } catch (err) {
    return err.code;
  }
}

export async function logoff() {
  try {
    await signOut(auth);
  } catch (err) {
    return err;
  }
}
