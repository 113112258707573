import React, { useState } from "react";
import { faUserCircle, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logoff } from "../../../../services/firebase/firebase";

import ClickOutside from "./ClickOutSide";
import { eraseCookie } from "../../../../utils/cookies";

import "./styles.css";

export default function SubMenu() {
  const [open, setOpen] = useState(false);

  const logout = async () => {
    await logoff();
    
    eraseCookie("ADMIN_ID");
    eraseCookie("ADMIN_NAME");
    eraseCookie("ADMIN_EMAIL");

    localStorage.removeItem("USER_TOKEN");
    localStorage.removeItem("EXPIRES_IN");
    
    window.location.pathname = "/admin/login";
  };

  return (
    <div>
      <ClickOutside
        onClickOutside={() => {
          setOpen(false);
        }}
      >
        <div
          className="perfilbtn"
          onMouseEnter={() => setOpen(true)}
          onClick={() => setOpen(true)}
        >
          <FontAwesomeIcon
            icon={faUserCircle}
            color="white"
            aria-hidden="false"
          />
          <span
            style={{
              textDecoration: "underline",
              color: "white",
              marginLeft: "2%",
            }}
          >
            Administrador
          </span>
        </div>
        {open && (
          <div className="submenu-items" onMouseLeave={() => setOpen(false)}>
            <span onClick={logout}>
              <FontAwesomeIcon icon={faSignOutAlt} />
              {" "}Sair
            </span>
          </div>
        )}
      </ClickOutside>
    </div>
  );
}
