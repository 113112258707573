import Divider from "@mui/material/Divider";

import "./style.css";

const BlockDivider = ({ label }) => {
  return (
    <div className="scope-header">
      <p>
        <strong>{label}</strong>
      </p>
      <Divider sx={{ margin: "0 0 10px 0" }} />
    </div>
  );
};

export default BlockDivider;
