import { useCallback, useEffect, useState, useContext } from "react";
import { ServicesContext } from "../../../../contexts/services";

import checkIcon from "../../../../assets/imgs/Ok.png";
import alertIcon from "../../../../assets/imgs/alert-icon.png";
import "./style.css";

const CurrentStatus = () => {
  const { formatedInfo } = useContext(ServicesContext);
  const { currentEvents } = formatedInfo;

  const [label, setLabel] = useState("");

  const buildStatusLabel = useCallback(() => {
    if (
      currentEvents.affectedServices.length === 0 &&
      currentEvents.currentIncidents.length === 0
    )
      return setLabel("Os serviços estão operacionais");

    if (
      currentEvents.affectedServices.length > 0 ||
      currentEvents.currentIncidents.length > 0
    ) {
      return setLabel(
        `${
          currentEvents.affectedServices.length > 1 ||
          currentEvents.currentIncidents.length > 1
            ? `Existem ocorrências em andamento.`
            : "Existe uma ocorrência em andamento."
        }`
      );
    }
  }, [
    currentEvents.affectedServices.length,
    currentEvents.currentIncidents.length,
  ]);

  useEffect(() => {
    buildStatusLabel();
  }, [currentEvents, buildStatusLabel]);

  function showMovideskChat() {
    window.postMessage(
      { action: "chatWidgetChangeWindowState", data: "maximized" },
      "*"
    );
  }

  return (
    <div id="current_status" className="current-status">
      <img
        src={
          currentEvents.affectedServices.length > 0 ||
          currentEvents.currentIncidents.length > 0
            ? alertIcon
            : checkIcon
        }
        alt="status icon"
        className={
          currentEvents.affectedServices.length > 0 ||
          currentEvents.currentIncidents.length > 0
            ? "alert-status-icon"
            : ""
        }
      />
      <div className="current-status-text">
        <h1>{label}</h1>
        {(currentEvents.affectedServices.length > 0 ||
          currentEvents.currentIncidents.length > 0) && (
          <>
            <p>
              Nossas equipes já estão atuando na correção. Acompanhe os{" "}
              <a className="redirect-incidents" href="/client/incidents">
                incidentes ativos
              </a>
              .
            </p>
            <p>Agradecemos a compreensão!</p>
          </>
        )}
        <p>
          Está tendo problemas?{" "}
          <span className="movidesk-label" onClick={showMovideskChat}>
            Entre em contato com nosso suporte.
          </span>
        </p>
      </div>
    </div>
  );
};

export default CurrentStatus;
