import { refreshToken } from "../repositories/token";

export async function getToken() {
  const token = localStorage.getItem("USER_TOKEN");
  const expiration = localStorage.getItem("EXPIRES_IN");

  if (token && expiration < new Date()) {
    return token;
  } else {
    const newToken = await refreshToken();

    return newToken;
  }
}
