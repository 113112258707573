import IconsSubtitle from "../IconsSubtitle";
import "./style.css";

const GridHeader = () => {
  return (
    <div id="services-grid-header" className="grid-header">
      <div className="grid-header-content">
        <div>
          <p>Status Atual por Serviço</p>
        </div>
        <div className="grid-header-subtitle">
          <IconsSubtitle />
        </div>
      </div>
    </div>
  );
};

export default GridHeader;
