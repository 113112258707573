import "./style.css";

function Main() {
  return (
    <>
      <p><strong>Olá, seja bem vindo a área logada da Kenlo Status Page!</strong></p>
      <p>Utilize o menu ao lado para navegar pelo site.</p>
    </>
  );
}

export default Main;
