const dotenv = require("dotenv");
dotenv.config();

const config = {};

const environment =
  window.location.href.includes("localhost") ||
  window.location.href.includes("staging")
    ? "staging"
    : "production";

config.endpoint =
  environment === "staging"
    ? process.env.REACT_APP_API
    : process.env.REACT_APP_API_PRD;

module.exports = config;
