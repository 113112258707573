import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faLock,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";

import { isAdminLogged } from "../../../../utils/validations";
import { setCookie } from "../../../../utils/cookies";

import logo from "../../../../assets/imgs/kenlo.svg";

import "./style.css";
import { firebaseAuthenticate } from "../../../../services/firebase/firebase";
import { getTokenFromAPI } from "../../../../repositories/token";
import { addDays } from "date-fns";

function AdminLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [isMessageVisible, setIsMessageVisible] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (isAdminLogged()) {
      history.push("/admin/");
    }
  }, [history]);

  const submit = async (e) => {
    e.preventDefault();
    try {
      const auth = await firebaseAuthenticate(email, password);

      if (!!auth.uid) {
        const { token } = await getTokenFromAPI(email);

        setCookie("ADMIN_ID", auth.uid);
        setCookie("ADMIN_NAME", "Admin");
        setCookie("ADMIN_EMAIL", email);
        localStorage.setItem("USER_TOKEN", token);
        localStorage.setItem("EXPIRES_IN", `${addDays(new Date(), 1)}`);

        history.push("/admin/");
      } else {
        setIsMessageVisible(true);
      }
    } catch (error) {
      setIsMessageVisible(true);
    }
  };

  return (
    <div className="login-container">
      <div className="login">
        <div className="login-card">
          <div
            style={{
              display: "flex",
              height: "200px",
              width: "200px",
              justifyContent: "center",
              flexDirection: "column",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <img className="logo-img" src={logo} alt="Kenlo" />
          </div>

          <form className="Login-form" onSubmit={submit}>
            <div className="Login-field">
              <div className="Login-icon">
                <FontAwesomeIcon icon={faUser} aria-hidden="false" />
              </div>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="Login-field">
              <div className="Login-icon">
                <FontAwesomeIcon icon={faLock} aria-hidden="false" />
              </div>
              <input
                type={show ? "text" : "password"}
                autoComplete="new-password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              <div className="Login-icon-left" onClick={() => setShow(!show)}>
                <FontAwesomeIcon
                  icon={show ? faEye : faEyeSlash}
                  aria-hidden="false"
                />
              </div>
            </div>

            <div
              style={{
                marginTop: 20,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <button className="btn btn-sucesso" type="submit">
                Entrar
              </button>
            </div>

            <span
              style={{
                marginTop: 5,
                color: "red",
                fontSize: 13,
                display: isMessageVisible ? "" : "none",
              }}
            >
              Credenciais inválidas, verifique as informações.
            </span>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AdminLogin;
