import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

import ServicesDataContext from "./contexts/services";

import AdminLogin from "./layouts/admin/pages/login";
import AdminLayout from "./layouts/admin";
import ClientLayout from "./layouts/client";

import "./index.css";

function App() {
  return (
    <ServicesDataContext>
      <Router>
        <Switch>
          <Redirect exact from="/" to="/client/" />
          <Route exact path="/admin/login" component={AdminLogin} />
          <Route path="/client*" component={ClientLayout} />
          <Route path="/admin*" component={AdminLayout} />
          <Redirect from="/*" to="/client/" />
        </Switch>
      </Router>
    </ServicesDataContext>
  );
}

export default App;
