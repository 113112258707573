import ClipLoader from "react-spinners/ClipLoader";

import "./style.css";

const Loading = () => {
  return (
    <div className="app-loading">
      <ClipLoader loading color="#F82E52" size={150} />
    </div>
  );
};

export default Loading;