import { getToken } from "../utils/getToken";

import api from "../services/api";
import config from "../config";

export async function getAllProducts() {
  try {
    const { data } = await api.get(config.endpoint + "status-page/products");
    return data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error(err.message);
    }
  }
}

export async function getById(id) {
  try {
    const { data } = await api.get(
      config.endpoint + `status-page/products/${id}`
    );
    return data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error(err.message);
    }
  }
}

export async function create(product) {
  try {
    const token = await getToken();
    const { data } = await api.post(
      config.endpoint + `status-page/products`,
      product,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error(err.message);
    }
  }
}

export async function update(product) {
  try {
    const token = await getToken();
    const { data } = await api.put(
      config.endpoint + `status-page/products`,
      product,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error(err.message);
    }
  }
}

export async function deleteProduct(id) {
  try {
    const token = await getToken();
    const { data } = await api.delete(
      config.endpoint + `status-page/products/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error(err.message);
    }
  }
}
