import KenloLogo from "../../../../assets/imgs/kenlo.svg";
import Endeavor from "../../../../assets/imgs/escale-up-endeavor.png";

import "./style.css";

const Footer = () => {
  return (
    <>
      <div className="wrapper w2">
        <div className="footer">
          <img
            src={KenloLogo}
            className="footer-logo"
            alt="Logotipo da Kenlo"
          />
          <div className="footer-content">
            <div className="group1">
              <div className="footer-title">Para imobiliárias e corretores</div>
              <div className="group-links">
                <div className="links">
                  <div>
                    Crédito com Imóvel em Garantia
                  </div>
                  <div>Locação Digital</div>
                  <div>Crédito Imobiliário</div>
                  <div>Laudo de Avaliação</div>
                </div>
                <div className="links">
                  <a
                    href="https://imob.ingaia.com.br"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Kenlo CRM
                  </a>
                  <a
                    href="https://locacao.ingaia.com.br"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Kenlo Locação
                  </a>
                  <a
                    href="https://pmi.ingaia.com.br"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Painel do Mercado Imobiliário
                  </a>
                </div>
              </div>
            </div>
            <div className="group2">
              <div className="footer-title">Para proprietários</div>
              <div className="group-links">
                <div className="links">
                  <div
                  >
                    Crédito com Imóvel em Garantia
                  </div>
                  <div>Laudo de avaliação</div>
                </div>
              </div>
            </div>
            <div className="group3">
              <div className="footer-title">Para interessados</div>
              <div className="group-links">
                <div className="links">
                  <div>Locação Digital</div>
                  <div>Crédito Imobiliário</div>
                </div>
              </div>
            </div>
          </div>
          <img
            src={Endeavor}
            className="endeavor-img"
            alt="Empresa participante do Scale Up"
          />
        </div>
      </div>
    </>
  );
};

export default Footer;
