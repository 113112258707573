import { getToken } from "../utils/getToken";

import api from "../services/api";
import config from "../config";

export async function getAllIncidents() {
  try {
    const { data } = await api.get(config.endpoint + "status-page/incidents");
    return data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error(err.message);
    }
  }
}

export async function getById(id) {
  try {
    const { data } = await api.get(
      config.endpoint + `status-page/incidents/${id}`
    );
    return data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error(err.message);
    }
  }
}

export async function create(incident) {
  try {
    const token = await getToken();
    const { data } = await api.post(
      config.endpoint + `status-page/incidents`,
      incident,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error(err.message);
    }
  }
}

export async function update(incident) {
  try {
    const token = await getToken();
    const { data } = await api.put(
      config.endpoint + `status-page/incidents`,
      incident,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error(err.message);
    }
  }
}

export async function getIncidentsStatus() {
  try {
    const { data } = await api.get(
      config.endpoint + `status-page/status-incidents`
    );
    return data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error(err.message);
    }
  }
}

export async function deleteIncident(id) {
  try {
    const token = await getToken();
    const data = await api.delete(
      config.endpoint + `status-page/incidents/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error(err.message);
    }
  }
}
