import { createContext, useState, useEffect, useCallback } from "react";
import { getAllServices } from "../repositories/services";
import { getAllIncidents } from "../repositories/incidents";
import { getAllProducts } from "../repositories/products";

import Loading from "../components/Loading";
import ErrorBoundary from "../components/ErrorBoundary";

export const ServicesContext = createContext({
  services: [],
  incidents: [],
  products: [],
  formatedInfo: {},
});

export default function ServicesDataContext({ children }) {
  const [services, setServices] = useState([]);
  const [incidents, setIncidents] = useState([]);
  const [products, setProducts] = useState([]);
  const [formatedInfo, setFormatedInfo] = useState({});
  const [loadingStatus, setLoadingStatus] = useState("loading");

  const gets = useCallback(async () => {
    const getServices = await getAllServices();
    const getIncidents = await getAllIncidents();
    const getProducts = await getAllProducts();

    const [resultServices, resultIncidents, resultProducts] = await Promise.all(
      [getServices, getIncidents, getProducts]
    ).catch((error) => {
      setLoadingStatus("error");
    });

    const processedData = await buildNewArray(
      resultServices,
      resultIncidents,
      resultProducts
    );

    setFormatedInfo(processedData);
    setServices(resultServices);
    setIncidents(resultIncidents);
    setProducts(resultProducts);
    setLoadingStatus("success");
  }, []);

  async function buildNewArray(services, incidents, products) {
    let newArray = {};

    let newArrayForProducts = products.map((product) => {
      return {
        _id: product._id,
        name: product.name,
        status: product.status,
        services: services.filter((p) => p.productId?._id === product._id),
      };
    });

    let formatedArray = newArrayForProducts.map((product) => {
      return {
        _id: product._id,
        product: product.name,
        status: product.status,
        productServices: product.services.sort(
          (a, b) => b.relevance - a.relevance
        ),
        affectedServices: [
          ...product.services.filter(
            (service) => service.statusServiceId.name !== "Operacional" && service.status
          ),
        ],
      };
    });

    let currentEvents = {
      affectedServices: services.filter(
        (service) => service.statusServiceId.name !== "Operacional" && service.status
      ),
      currentIncidents: incidents.filter(
        (incident) => incident.statusIncident.name !== "Resolvido"
      ),
    };

    newArray = {
      currentEvents,
      products: formatedArray,
    };

    return newArray;
  }

  useEffect(() => {
    setLoadingStatus("loading");
    (async () => {
      try {
        await gets();
      } catch (e) {
        setLoadingStatus("error");
      }
    })();
  }, [gets]);

  return (
    <ServicesContext.Provider
      value={{
        services,
        incidents,
        products,
        formatedInfo,
      }}
    >
      {loadingStatus === "success" && children}
      {loadingStatus === "loading" && <Loading />}
      {loadingStatus === "error" && <ErrorBoundary />}
    </ServicesContext.Provider>
  );
}
