import { getToken } from "../utils/getToken";

import api from "../services/api";
import config from "../config";

export async function getAllServices() {
  try {
    const { data } = await api.get(config.endpoint + "status-page/services");
    return data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error(err.message);
    }
  }
}

export async function getById(id) {
  try {
    const { data } = await api.get(
      config.endpoint + `status-page/services/${id}`
    );
    return data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error(err.message);
    }
  }
}

export async function create(service) {
  try {
    const token = await getToken();
    const { data } = await api.post(
      config.endpoint + `status-page/services`,
      service,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error(err.message);
    }
  }
}

export async function update(service) {
  try {
    const token = await getToken();
    const { data } = await api.put(
      config.endpoint + `status-page/services`,
      service,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error(err.message);
    }
  }
}

export async function getServicesStatus() {
  try {
    const { data } = await api.get(
      config.endpoint + `status-page/status-services`
    );
    return data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error(err.message);
    }
  }
}

export async function deleteService(id) {
  try {
    const token = await getToken();
    const { data } = await api.delete(
      config.endpoint + `status-page/services/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (err) {
    if (err.response) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error(err.message);
    }
  }
}
