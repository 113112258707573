import { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import LoadingButton from "@mui/lab/LoadingButton";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";

import BlockDivider from "../../components/BlockDivider";
import BreadCrumb from "../../components/BreadCrumb";
import { getById, create, update } from "../../../../repositories/products";

import { forName } from "../../../../utils/validations";

import "./style.css";

function Product({ match, history }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [productId, setProductId] = useState(null);
  const [values, setValues] = useState({
    name: "",
  });
  const [isActive, setIsActive] = useState(true);

  const settingInfoToForm = useCallback((values) => {
    const { name, status } = values;

    setValues({
      name: name ? name : null,
    });
    setIsActive(status);
  }, []);

  const loadProductInfo = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getById(productId);
      settingInfoToForm(response);
    } catch (err) {
      toast.error("Ocorreu um problema ao carregar os dados do produto.");
    } finally {
      setIsLoading(false);
    }
  }, [productId, settingInfoToForm]);

  useEffect(() => {
    if (!!match.params.id) {
      setProductId(match.params.id);
    }
  }, [match.params.id]);

  useEffect(() => {
    if (!productId) {
      document.title = "Novo Produto";
    } else {
      loadProductInfo();
    }
  }, [productId, loadProductInfo]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleIsActiveChange = (event) => {
    setIsActive(event.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!!productId) {
      updateProduct(values);
    } else {
      createProduct(values);
    }
  };

  const createProduct = async (product) => {
    try {
      setIsSaving(true);
      await create({ name: forName(product.name), status: isActive });
      toast.success("O produto foi cadastrado com sucesso!");
      history.goBack();
    } catch (err) {
      toast.error(`Ocorreu um problema ao salvar o produto. ${err}`);
    } finally {
      setIsSaving(false);
    }
  };

  const updateProduct = async (product) => {
    try {
      setIsSaving(true);
      await update({
        _id: productId,
        name: forName(product.name),
        status: isActive,
      });
      toast.success("O produto foi atualizado com sucesso!");
      history.goBack();
    } catch (err) {
      toast.error(`Ocorreu um problema ao atualizar o produto. ${err}`);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      <BreadCrumb
        path={["", "", null]}
        data={[
          "Início",
          "Produtos",
          match.params.id ? "Editar Produto" : "Novo Produto",
        ]}
      />
      <form onSubmit={handleSubmit}>
        <BlockDivider label="Dados Gerais" />
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          <div style={{ width: "100%" }}>
            <TextField
              label="Nome do Produto"
              id="product-name-field"
              sx={[{ m: 1, width: "50%" }]}
              onChange={handleChange("name")}
              value={forName(values.name)}
              required
              variant="standard"
              type="text"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 50,
              }}
            />
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={isActive}
                  onChange={handleIsActiveChange}
                />
              }
              label="Ativo?"
              labelPlacement="top"
              required
            />
          </div>
        </Box>

        <Box sx={{ marginTop: "30px" }}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <LoadingButton
              loading={isSaving}
              variant="contained"
              color="success"
              loadingIndicator="SALVANDO..."
              sx={{
                marginRight: "10px",
                width: "110px",
              }}
              type="submit"
            >
              Salvar
            </LoadingButton>
            <Button
              variant="contained"
              color="error"
              onClick={() => history.goBack()}
            >
              Cancelar
            </Button>
          </div>
        </Box>
      </form>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default Product;
