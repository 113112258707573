import React, { useState } from "react";
import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import {
  faHome,
  faBoxOpen,
  faServer,
  faBolt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import HeaderNav from "../HeaderNav";

import "./SideNav.css";

export default function Menu({ expanded, onToggle, location, history }) {
  const [path, setPath] = useState("");

  return (
    <div>
      <HeaderNav />
      <SideNav
        onToggle={() => onToggle(!expanded)}
        style={{ position: "fixed" }}
        expanded={expanded}
        onSelect={(selected) => {
          const to = "/" + selected;
          if (location.pathname !== to && selected !== "fixed") {
            history.push(to);
            setPath(selected);
          }
        }}
      >
        <SideNav.Nav defaultSelected={"admin/"} style={{ height: "100vh" }}>
          <NavItem eventKey="fixed">
            <NavIcon>
              <SideNav.Toggle
                id="side-toggle"
                onClick={() => onToggle(!expanded)}
              />
            </NavIcon>
          </NavItem>

          <NavItem
            eventKey="admin/"
            title="Dashboard"
            active={path === "admin/"}
          >
            <NavIcon>
              <FontAwesomeIcon
                icon={faHome}
                color="blue"
                className="iconeMenu"
                style={{ backgroundColor: "yellow !important" }}
                aria-hidden="false"
              />
            </NavIcon>
            <NavText style={{ color: "white" }}>Dashboard</NavText>
          </NavItem>

          <NavItem
            eventKey="admin/products"
            title="Produtos"
            active={path === "admin/products"}
          >
            <NavIcon>
              <FontAwesomeIcon
                icon={faBoxOpen}
                className="iconeMenu"
                aria-hidden="false"
              />
            </NavIcon>
            <NavText style={{ color: "white" }}>Produtos</NavText>
          </NavItem>

          <NavItem
            eventKey="admin/services"
            title="Serviços"
            active={path === "admin/services"}
          >
            <NavIcon>
              <FontAwesomeIcon
                icon={faServer}
                className="iconeMenu"
                aria-hidden="false"
              />
            </NavIcon>
            <NavText style={{ color: "white" }}>Serviços</NavText>
          </NavItem>

          <NavItem
            eventKey="admin/incidents"
            title="Incidentes"
            active={path === "admin/incidents"}
          >
            <NavIcon>
              <FontAwesomeIcon icon={faBolt} className="iconeMenu" />
            </NavIcon>
            <NavText style={{ color: "white" }}>Incidentes</NavText>
          </NavItem>
        </SideNav.Nav>
      </SideNav>
    </div>
  );
}
