import { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import MainCard from "./components/MainCard";
import Menu from "./components/Menu";

import { isAdminLogged } from "../../utils/validations";

import routes from "./routes";

function AdminLayout(props) {
  const [expanded, setExpanded] = useState(false);

  function getRoutes(routes) {
    return routes.map((prop, key) => (
      <Route
        exact
        path={prop.layout + prop.path}
        render={(props) => <prop.component {...props} />}
        key={key}
      />
    ));
  }

  useEffect(() => {
    if (!isAdminLogged()) window.location.href = "/admin/login";
  }, []);

  return (
    <>
      {isAdminLogged() && (
        <>
          <Menu
            expanded={expanded}
            onToggle={(e) => setExpanded(e)}
            {...props}
          />
          <MainCard className="main-card-content-margin main-card-content">
            <Switch>{getRoutes(routes)}</Switch>
          </MainCard>
          <ToastContainer />
        </>
      )}
    </>
  );
}

export default AdminLayout;
